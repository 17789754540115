@use 'sass:math'

.wrapper
	display: flex
	width: 100%
	justify-content: center

.large
	max-width: 100%
	max-height: 3.125rem
	margin-inline: auto

.small
	width: 100%
	aspect-ratio: math.div(36, 35)
